import {ApolloClient, InMemoryCache } from '@apollo/client';
import fetch from 'isomorphic-fetch';
import Cookies from 'js-cookie';

const token = Cookies.get('st');

const client = new ApolloClient({
	uri: 'https://pg-app-kku833xcn4w1y2449ka9igu9zdxyck.scalabl.cloud/graphql',
	fetchOptions: {
		mode: 'no-cors'
	},
	headers: {
		'X-Parse-Application-Id': process.env.GATSBY_SASHIDO_ACCESS_TOKEN,
		'X-Parse-Master-Key': process.env.GATSBY_SASHIDO_MASTER_KEY,
		'X-Parse-Session-Token': token

	},
	connectToDevTools: true,
	cache: new InMemoryCache({
		typePolicies: {
			ObjectsQuery: {
				queryType: true,
				merge(existing, incoming, { mergeObjects }) {
					return mergeObjects(existing, incoming);
				  }
			}
		  }
	}),
	fetch
});

export default client;

