/* eslint-disable no-useless-escape */
import dayjs from 'dayjs';
import 'dayjs/locale/de';
import constants from './constants';

const getDateFromIso = (datum) => {
	const date = new Date(datum);
	const isoDateTime = new Date(date.getTime() + (date.getTimezoneOffset() * 60000)).toISOString();
	return isoDateTime;
};

const functions = {
	dateHandler: (time) => {
		const start = new Date(time);
		const st = dayjs(start).locale('de').format('DD. MMMM YYYY');
		return st;
	},
    
	slugify: (string) => {
		const a = 'àáâäæãåāăąçćčđďèéêëēėęěğǵḧîïíīįìłḿñńǹňôöòóœøōõőṕŕřßśšşșťțûüùúūǘůűųẃẍÿýžźż·/_,:;';
		const b = 'aaaaaaaaaacccddeeeeeeeegghiiiiiilmnnnnoooooooooprrsssssttuuuuuuuuuwxyyzzz------';
		const p = new RegExp(a.split('').join('|'), 'g');
		if (string) {
			return string.toString().toLowerCase()
				.replace(/\s+/g, '-') // Replace spaces with -
				.replace(p, c => b.charAt(a.indexOf(c))) // Replace special characters
				.replace(/&/g, '-and-') // Replace & with 'and'
				.replace(/[^\w\-]+/g, '') // Remove all non-word characters
				.replace(/\-\-+/g, '-') // Replace multiple - with single -
				.replace(/^-+/, '') // Trim - from start of text
				.replace(/-+$/, ''); // Trim - from end of text
		}
		return '';
	},

	logoHandler: (logo) => {
		if (logo) {
			const logoUrl = `${logo.cdnUrl}-/preview/-/resize/40x/`;
			return logoUrl;
		}
		return null;
	},

	dateToStringHandler: (datum) => {
		const date = new Date(datum);
		const year = date.getFullYear();
		const month = date.getMonth() + 1;
		const day = date.getDate();
	
		const hours = date.getHours();
		const minutes = date.getMinutes() < 10 ? `	0${  date.getMinutes()}` : date.getMinutes();
		return {
			datum: `${day  }.${  month  }.${  year}`,
			uhrzeit: `${hours  }:${  minutes}`
		};
	},

	spielklasseHandler: spielklasseValue => {
		let returnValue = {};
		constants.spielklasse.forEach(spielklasse => {
			if (spielklasse.value === spielklasseValue) {
				returnValue = spielklasse;
			}
		});
		return returnValue;
	}
};


export default functions;