import spielklasseHandler from './spielklasseHandler';

const teamNameHandler = (meldung, meldungen, showClass, short) => {
	const teamCopy = {
		name: meldung?.verein?.name,
		spielklasse: meldung.spielklasse,
		meldungId: meldung.objectId,
		label: 'Unbekannt'
	};
	const shortName = meldung?.verein?.name_kurz || `${meldung?.verein?.name?.slice(0,4)} ...`;
	const teamName = short ? shortName : teamCopy.name;
	if (typeof meldung === 'object' && meldung?.verein.name) {
		const doublicateArray = [];
		meldungen.forEach((md) => {
			if (md?.verein?.name === teamName) {
				doublicateArray.push(md);
			}
		});
		
		if (doublicateArray.length > 1) {
			teamCopy.label = showClass ? `${teamName} ${meldung.nummer} - ${spielklasseHandler(meldung.spielklasse).short}`: `${teamName} ${meldung.nummer}`;
		} else {
			teamCopy.label = showClass ?`${teamName} - ${spielklasseHandler(meldung.spielklasse).short}`: teamName ;
		}
		
	};

	return teamCopy;
};

export default teamNameHandler;