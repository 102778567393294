const componentStyles = {
	borderRadius: {
		small: '4px',
		medium: '8px',
		large: '12px'
	},
	fontSize: {
		tiny: '8px',
		small: '10px',
		semismall: '12px',
		medium: '14px',
		semilarge: '18px',
		large: '22px'
	},
	padding: {
		tiny: '2px 4px',
		small: '3px 6px',
		medium: '6px 12px',
		large: '9px 18px'
	},
	sizes: {
		small: '6px',
		medium: '12px',
		large: '18px',
		maximunm: '36px'
	},
	shadows: {
		light: '-2px 2px 2px 0 rgb(0 0 0 / 10%), 0 3px 1px -2px rgb(0 0 0 / 10%), 0 1px 5px 0 rgb(0 0 0 / 18%)', 
		medium: '-2px 2px 2px 0 rgb(0 0 0 / 14%), 0 3px 1px -2px rgb(0 0 0 / 24%), 0 -1px 5px 0 rgb(0 0 0 / 24%)' 
	}
};
  
export default componentStyles;